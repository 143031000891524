import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import Layout from '../common/Layout';
import Jumbotron from '../common/Jumbotron';
import * as Actions from './actions/forgot-password.actions';
// import AuthBackgroundSection from '../../components/auth/AuthBackgroundSection';
import {
	networkNotification
} from '../../../components/notifications/Notifications';
// import InterwindLoader from '../../assets/images/loaders/circular.gif';



class ForgotPassword extends Component {
	constructor(){
		super();
		this.state = {
			email : ''
		}
		
    }
    
    componentDidMount(){

	}


	handleChange = (e) => {
        e.preventDefault();
		this.setState({[e.target.name]: e.target.value});
    }
    

	sendPasswordResetLink = async (e) => {
        e.preventDefault();

        const { actions } = this.props;

        await actions.beforeSendPasswordResetLink();
        await actions.sendPasswordResetLink(this.state);

        let { forgotPasswordReducer } = this.props;
		let { messages, status_code} = forgotPasswordReducer;

		if(status_code === 200){
            networkNotification(messages, status_code);
		}else{
			networkNotification(messages, status_code);
		}
	}
	
	render() {
        
        let { forgotPasswordReducer } = this.props;
		let { status_code, loading, email } = forgotPasswordReducer;

		return (
            <Layout>
				<div className="text-center py-5">
					
						<a href="/"><img className="mb-4" src="/logo-black.png" alt="" height="45" /></a>

                        {status_code === 200
                        ?
                            <Jumbotron 
                                title="Password Reset Link sent to your E-Mail."
                                message={`Please check the inbox of your E-Mail: ${email} for the password reset link we just sent to you.`}
                                linkTitle="Return To Login Page"
                                linkHref="/" 
                                displayButton={false}
                            />
                        :
                            <form className="form-signin">
                                <h1 className="h3 mb-3 font-weight-normal">Forgot Password</h1>
                                <label for="inputEmail" className="sr-only">Email address</label>
                                <input disabled={loading} type="email" onChange={(e) => this.handleChange(e)} id="inputEmail" className="form-control" value={email} name="email" placeholder="Email address" required autofocus />
                                <div className="checkbox mb-3">
                                    
                                </div>
                                <button disabled={loading} className="btn btn-lg btn-danger btn-block" type="submit" onClick={(e) => this.sendPasswordResetLink(e)}>
                                    {loading?
                                        <><i className="fa fa-spinner fa-spin"></i> Sending Reset Link...</>
                                    :
                                        <>Proceed</>
                                    }
                                </button>
                                <p className="mt-3 mb-3 text-muted"><a href="/">Return To Login Page</a></p>
                            </form>
                        }

                        
					
				</div>


                

			</Layout>
		);
	}
}

const mapStateToProps = (state) => ({ forgotPasswordReducer: state.forgotPasswordReducer });

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgotPassword));
