import axios from 'axios';

import{
    BEFORE_VALIDATE_TOKEN,
    VALIDATE_TOKEN,
    NETWORK_ERROR_VALIDATING_TOKEN,

    BEFORE_RESET_PASSWORD,
    RESET_PASSWORD,
    NETWORK_ERROR_RESETTING_PASSWORD
} from '../types/password-reset.types';

import {
    endpoint
} from '../../../../config';


export const beforeValidateToken = () => (
    { type: BEFORE_VALIDATE_TOKEN }
)

export const validateToken = (data) => (
    async (dispatch) => {
        try{
            const response = await axios({
                method: 'post',
                url: `${endpoint}user/forgot-password/validate-link`,
                headers: {
                    "content-type": "application/json",
                    "Accept" : "application/json",
                },
                data
            });

            dispatch({ type: VALIDATE_TOKEN, payload: response });
        }catch(e){
            dispatch({ type: NETWORK_ERROR_VALIDATING_TOKEN, payload: e.response });
        }
    }
)

export const beforeResetPassword = () => (
    { type: BEFORE_RESET_PASSWORD }
)

export const resetPassword = (data) => (
    async (dispatch) => {
        try{
            const response = await axios({
                method: 'post',
                url: `${endpoint}user/forgot-password/reset-password`,
                headers: {
                    "content-type": "application/json",
                    "Accept" : "application/json",
                },
                data
            });
            
            dispatch({ type: RESET_PASSWORD, payload: response });
        }catch(e){
            dispatch({ type: NETWORK_ERROR_RESETTING_PASSWORD, payload: e.response });
        }
    }
)