import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import Layout from '../common/Layout';
import * as Actions from './actions/login.actions';
import {
	getLocalToken,
	clearLocalToken,
	setLocalToken
} from "helpers/authentication/token";
import {
	getParameter
} from 'helpers/url/url-parameters';
import { networkNotification } from 'components/notifications/Notifications';


class Login extends Component {
	constructor(){
		super();
		this.state = {
			email : '',
			password : '',
			redirect_url: '',
			product_code: ''
		}
		this.login = this.login.bind(this);
		this.updateFormState = this.updateFormState.bind(this);
	}

	/*
	async componentDidMount(){
		//if token is valid, with no valid redirect, go to apps page
		//if token is valid with valid redirect go to valid redirect 
		//if token is invalid clear token and present login page
		const token = getLocalToken();
		if(token !== null){
			const { verifyLogin, beforeVerifyLogin } = this.props.actions;
			let redirect_url = getParameter('redirect_url', null);
			let product_code = getParameter('product_code', null);
			await beforeVerifyLogin();
			await verifyLogin(token, {product_code, redirect_url});

			const { verification_auth_user, verification_status_code } = this.props.loginReducer;
			const { verification_redirect_url } = this.props.loginReducer;
			if(verification_status_code == 200 && verification_auth_user){
				//token is valid, now we decide on rediretion,
				//if redirect is valid move to location else move to apps route
				if(verification_redirect_url){
					window.location.href = verification_redirect_url;
				}else{
					window.location = '/apps';
				}
			}else{
				//Clear Local Token 
				clearLocalToken();
			}
		}
    }
	*/
	

	updateFormState = (e) => {
		e.preventDefault();
		this.setState({[e.target.name]: e.target.value});
	}

	login = async (event) => {
		event.preventDefault();

		let redirectUrl =  event.target.redirect_url.value;
		let productCode = event.target.product_code.value;
		const { beforeLogin, login } = this.props.actions;
		await beforeLogin();
		await login({
			email: this.state.email, 
			password: this.state.password, 
			redirect_url: redirectUrl, 
			product_code: productCode
		});

		let { token, redirect_url, status_code, messages } = this.props.loginReducer;
		networkNotification(messages, status_code);
		if(status_code == 200 && token){
			setLocalToken(token);
			if(redirect_url){
				window.location.href = redirect_url;
			}else{
				window.location = '/apps';
			}
		}
	}

	render() {

		const { email, password } = this.state;
		const { loginReducer } = this.props;
		const { loading } = loginReducer;

		let redirectUrl = getParameter('redirect_url', null);
		let productCode = getParameter('product_code', null);

		return (
			<Layout>
				<div className="text-center py-5">
					<form className="form-signin" onSubmit={this.login}>
						<a href="/">
							<img className="mb-4" src="/logo-black.png" alt="" height="45" />
						</a>
						<h1 className="h3 mb-3 font-weight-normal">Please Sign In</h1>
						<label for="inputEmail" className="sr-only">Email address</label>
						<input disabled={loading} type="email" onChange={this.updateFormState} id="inputEmail" className="form-control at-top" value={email} name="email" placeholder="Email address" required autofocus />
						<label for="inputPassword" className="sr-only">Password</label>
						<input disabled={loading}  type="password" onChange={this.updateFormState} id="inputPassword" className="form-control at-bottom" value={password} name="password" placeholder="Password" required />
						<div className="checkbox mb-3">
							<label>
							<input type="checkbox" value="remember-me" /> Remember me
							</label>
						</div>
						<input type="hidden" name="redirect_url" value={redirectUrl} />
						<input type="hidden" name="product_code" value={productCode} />
						<button disabled={loading} className="btn btn-lg btn-danger btn-block" type="submit">
							{loading?
								<><i className="fa fa-spinner fa-spin"></i> Authenticating...</>
							:
								<>Sign in</>
							}
						</button>

						<div className="row">
							<div className="col-sm-12">
								<p className="mt-3 text-muted">Don't have an account? <a href="/register">Register</a></p>
							</div>

							<div className="col-sm-12">
								<p className="mt-3 text-muted">Having Issues Signing In? <a href="/forgot-password">Recover Password?</a></p>
							</div>
						</div>
						
					</form>
				</div>

			</Layout>
		);
	}
}

const mapStateToProps = (state) => ({ loginReducer: state.loginReducer });

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
