import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import * as Actions from './actions/resend-verification.actions';
import {
	networkNotification
} from '../../../components/notifications/Notifications';
import Layout from '../common/Layout';
import Jumbotron from '../common/Jumbotron';


class ResendVerification extends Component {
	constructor(){
		super();
		this.state = {
			email : ''
		}
		
    }
    
    componentDidMount(){

	}


	handleChange = (e) => {
        e.preventDefault();
		this.setState({[e.target.name]: e.target.value});
    }
    

	resendEmailVerification = async (e) => {
        e.preventDefault();

        const { actions } = this.props;

        await actions.beforeResendEmailVerification();
        await actions.resendEmailVerification(this.state);

        let { resendVerificationReducer } = this.props;
		let { messages, status_code} = resendVerificationReducer;

		if(status_code === 200){
            networkNotification(messages, status_code);
		}else{
			networkNotification(messages, status_code);
		}
    }
    
    flattenMessages = (messages) => {
		const block = (<div>
			{
				messages.map(element => 
					<p>{element}</p> 
				)
			}
		</div>);

		return block;
	}
	
	render() {
        
        let { resendVerificationReducer } = this.props;
        let { status_code, loading, messages } = resendVerificationReducer;
        const { email } = this.state;

		return (
			<Layout auth_user={this.props.auth_user}>
                <div className="text-center py-5">

                    <a href="/">
                        <img className="mb-4" src="/logo-black.png" alt="" height="45" /> 
                    </a>

                    {status_code === 200
                    ?
                        <Jumbotron 
                            title="Verification Feedback."
                            message={this.flattenMessages(messages)}
                            linkTitle="Proceed to Login"
                            linkHref="/" 
                            displayButton={false}
                            buttonClass="success"
                        /> 
                    :
                        <form className="form-signin">
                            <h1 className="h3 mb-3 font-weight-normal">Resend Verification</h1>
                            <label for="inputEmail" className="sr-only">Email address</label>
                            <input disabled={loading} type="email" onChange={(e) => this.handleChange(e)} id="inputEmail" className="form-control" value={email} name="email" placeholder="Email address" required autofocus />
                            <div className="checkbox mb-3">
                                
                            </div>
                            <button disabled={loading} className="btn btn-lg btn-danger btn-block" type="submit" onClick={(e) => this.resendEmailVerification(e)}>
                                {loading?
                                    <><i className="fa fa-spinner fa-spin"></i> Resending...</>
                                :
                                    <>Proceed</>
                                }
                            </button>

                            {!this.props.auth_user &&
                                <p className="mt-3 mb-3 text-muted"><a href="/">Return To Login Page</a></p>
                            }
                            
                        </form>   
                    }
                </div>
            </Layout>
		);
	}
}

const mapStateToProps = (state) => ({ resendVerificationReducer: state.resendVerificationReducer });

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResendVerification));
