import axios from 'axios';
import {
    BEFORE_REGISTER_NEW_USER,
    REGISTER_NEW_USER,
    NETWORK_ERROR_REGISTERING_USER
} from "../types/register.types";

import {endpoint} from "config";

export const beforeRegister = () => (
    { type: BEFORE_REGISTER_NEW_USER, payload: true }
);


export const register = (data) => {
    return async (dispatch) => {
        try{
            const response = await axios({
                method: 'post',
                url: `${endpoint}register`,
                headers: {
                    "Content-Type": 'application/json',
                    "Accept" : "application/json",
                },
                data
            });

            dispatch({ type: REGISTER_NEW_USER, payload: response })
        }catch(e){
            dispatch({ type: NETWORK_ERROR_REGISTERING_USER, payload: e.response });
        }
    }
}



/*
export const register = (body) => (
    async (dispatch) => {

        postRequest('v1', 'register', body)
        .then((data)=>{
            console.log(data)
            networkNotification([data.message], data.status);
            dispatch({ type: REGISTER_NEW_USER, payload: data })
        })
        .catch((error)=>{
            handleError(error)
            dispatch({ type: NETWORK_ERROR_REGISTERING_USER, payload: error });
        })
                  
    }
);
*/
