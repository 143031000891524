import axios from 'axios';
import {
    BEFORE_VERIFY_EXISTING_TOKEN,
    VERIFY_EXISTING_TOKEN,
    NETWORK_ERROR_VERIFYING_EXISTING_TOKEN
} from '../types/verify-authentication.types';

import {endpoint} from "config";

export const beforeVerifyLogin = () => (
    { type: BEFORE_VERIFY_EXISTING_TOKEN }
);

export const verifyLogin = (token, data) => (
    async (dispatch) => {
        try{
            const response = await axios({
                method: 'post',
                url: `${endpoint}auth`,
                headers: {
                    "Accept" : "application/json",
                    "Authorization" : `Bearer ${token}`
                },
                data
            });
            dispatch({ type: VERIFY_EXISTING_TOKEN, payload: response });
        }catch(e){
            dispatch({type: NETWORK_ERROR_VERIFYING_EXISTING_TOKEN, payload: e.response });
        }
    }
)
