import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';


import * as Actions from './actions/verify-authentication.actions';
import {
	getLocalToken,
	clearLocalToken,
	setLocalToken
} from "helpers/authentication/token";
import {
	getParameter
} from 'helpers/url/url-parameters';


class VerifyAuthentication extends Component {
	
	constructor(props){
		super(props);
		this.state = {
			user: null
		}
	}

	async componentDidMount(){
		//if token is valid, with no valid redirect, go to apps page
		//if token is valid with valid redirect go to valid redirect 
		//if token is invalid clear token and present login page
		const token = getLocalToken();
		if(token !== null){
			const { verifyLogin, beforeVerifyLogin } = this.props.actions;
			let redirect_url = getParameter('redirect_url', null);
			let product_code = getParameter('product_code', null);
			await beforeVerifyLogin();
			await verifyLogin(token, {product_code, redirect_url});

			const { verification_auth_user, verification_status_code } = this.props.verifyAuthenticationReducer;
			const { verification_redirect_url } = this.props.verifyAuthenticationReducer;
			if(verification_status_code == 200 && verification_auth_user){
				/**
				 * token is valid, now we decide on rediretion,
				 * if redirect is valid move to location else move to apps route
				 */
				if(verification_redirect_url){
					window.location.href = verification_redirect_url;
				}else{
					/* If not already on apps route, redirect to apps route. */
					if(window.location.pathname !== '/apps'){
						window.location = '/apps';
					}
				}
			}else{
				/* Clear Local Token */
				clearLocalToken();
			}
		}
    }


	render() {

		const { user } = this.state;
		const { verification_loading } = this.props.verifyAuthenticationReducer;

		return (
			<>
				{verification_loading
				?
				<></>
				:
				this.props.children
				}
			</>
		);
	}
}

const mapStateToProps = (state) => ({ verifyAuthenticationReducer: state.verifyAuthenticationReducer });

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VerifyAuthentication));
