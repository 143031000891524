import { combineReducers } from "redux";

import logoutEventReducer from './components/logoutEvent/reducers/logout-event.reducer';
import loginReducer from './pages/theme-four/login/reducers/login.reducer';
import verifyEmailReducer from './pages/theme-four/verify-email/reducers/verify-email.reducer'
import registerReducer from './pages/theme-four/register/reducers/register.reducer';
import forgotPasswordReducer from './pages/theme-four/forgot-password/reducers/forgot-password.reducer';
import passwordResetReducer from './pages/theme-four/password-reset/reducers/password-reset.reducer';
import resendVerificationReducer from './pages/theme-four/resend-verification/reducers/resend-verification.reducer';
import appsReducer from './pages/theme-four/apps/reducers/apps.reducer';
import verifyAuthenticationReducer from './pages/theme-four/common/middlewares/verify-authentication/reducers/verify-authentication.reducer';
import authenticationRequiredReducer from './pages/theme-four/common/middlewares/authentication-required/reducers/authentication-required.reducer';

export default combineReducers({
	logoutEventReducer,
	loginReducer, 
	verifyEmailReducer,
	registerReducer,
	forgotPasswordReducer,
	passwordResetReducer,
	resendVerificationReducer,
	appsReducer,
	verifyAuthenticationReducer,
	authenticationRequiredReducer
});
