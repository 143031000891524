import {
    BEFORE_FETCH_APPS,
    FETCH_APPS,
    NETWORK_ERROR_FETCHING_APPS
} from '../types/apps.types';

const initialState = {
    loading: false,
    status_code: null,
    messages: [],
}

export default function(state = initialState, action){
    switch(action.type){
        case BEFORE_FETCH_APPS: {
            return Object.assign({}, state, {
                loading: true
            });
        }
        case FETCH_APPS: {
            return Object.assign({}, state, {
                loading: false
            }); 
        }    
        case NETWORK_ERROR_FETCHING_APPS: {
            return Object.assign({}, state, {
                loading: false,
            });
        }
        default:
            return state;         
    }
}