import React from 'react';
import Jumbotron from '../../common/Jumbotron';

const PasswordForm = (props) => {

    const { reset_email, status_code, loading, handleChange, performPasswordReset, password, confirm_password } = props;

    return(

        <div>
            {status_code === 200
            ?
                <Jumbotron 
                    title="Password Reset Successful."
                    message={`Your password has been reset successfully, click the button below to access the login page.`}
                    linkTitle="Proceed to login with your new password"
                    linkHref="/" 
                    displayButton={true}
                    buttonClass="success"
                />
            :
                <form className="form-signin">
                    <h1 className="h3 mb-3 font-weight-normal">Reset Password</h1>
                    
                    <label for="inputEmail" className="sr-only">Email address</label>
                    <input disabled={true} type="email" id="inputEmail" className="form-control at-top" value={reset_email} />
                    
                    <label for="inputPassword" className="sr-only">Password</label>
                    <input disabled={loading}  type="password" onChange={handleChange} id="inputPassword" className="form-control in-between" value={password} name="password" placeholder="Password" required />
                    
                    <label for="inputPassword" className="sr-only">Confirm Password</label>
                    <input disabled={loading}  type="password" onChange={handleChange} id="inputPassword" className="form-control at-bottom" value={confirm_password} name="confirm_password" placeholder="Confirm Password" required />
                    
                    <div className="checkbox mb-3"> </div>
                    <button disabled={loading} className="btn btn-lg btn-danger btn-block" type="submit" onClick={performPasswordReset}>
                        {loading?
                            <><i className="fa fa-spinner fa-spin"></i> Resetting...</>
                        :
                            <>Reset Password</>
                        }
                    </button>
                    <p className="mt-3 mb-3 text-muted"><a href="/">Return To Login Page</a></p>
                </form>
            }
        </div>
    );
}

export default PasswordForm;