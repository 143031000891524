import {
    BEFORE_CHECK_REQUIRED_AUTHENTICATION,
    CHECK_REQUIRED_AUTHENTICATION,
    NETWORK_ERROR_CHECKING_REQUIRED_AUTHENTICATION
} from '../types/authentication-required.types';

const initialState = {
    verification_loading: false,
    verification_auth_user: null,
    verification_status_code: null,
    verification_messages: [],
    verification_redirect_url: null
}

export default function(state = initialState, action){
    switch(action.type){
        case BEFORE_CHECK_REQUIRED_AUTHENTICATION: {
            return Object.assign({}, state, {
                verification_loading: true,
            });
        }
        case CHECK_REQUIRED_AUTHENTICATION: {
            return Object.assign({}, state, {
                verification_loading: false,
                verification_auth_user: action.payload.data.result.user,
                verification_status_code: action.payload.status,
                verification_messages: action.payload.data.message,
                verification_redirect_url: action.payload.data.result.redirect_url
            });
        }
        case NETWORK_ERROR_CHECKING_REQUIRED_AUTHENTICATION: {
            return Object.assign({}, state, {
                verification_loading: false,
                verification_status_code: action.payload.status,
                verification_messages: action.payload.data.message
            }); 
        }
        default:
            return state;         
    }
}