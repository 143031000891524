import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import Layout from '../common/Layout';
import * as Actions from './actions/apps.actions';
import {
	getLocalToken,
	clearLocalToken,
	setLocalToken
} from "helpers/authentication/token";
import {
	getParameter
} from 'helpers/url/url-parameters';


class Apps extends Component{

    constructor(props){
        super(props);
    }



    render(){
        return(
            <Layout auth_user={this.props.auth_user}>

                <div class="row">
                    <div class="col-sm-12 col-md-4">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title">Advertisement App</h5>
                                <p class="card-text">Leverage our advertisement network to go viral, or simply earn by promoting ads on the network.</p>
                                <a href={`https://ads.cluster.live/auth?token=${getLocalToken()}`} class="btn btn-danger">Visit ads.cluster.live</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-4">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title">Market Place App</h5>
                                <p class="card-text">Quick Search, Quick Walk In, Quick Purchase. Search for retailers close to your geographic location.</p>
                                <a href={`https://cluster.live/auth?token=${getLocalToken()}`} class="btn btn-danger">Visit cluster.live</a>
                            </div>
                        </div>
                    </div>

                </div>

            </Layout>
        );
    }
}


const mapStateToProps = (state) => ({ appsReducer: state.appsReducer });

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Apps));