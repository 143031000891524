export const BEFORE_LOGIN_FULL_PAGE = 'BEFORE_LOGIN_FULL_PAGE';
export const LOGIN_FULL_PAGE = 'LOGIN_FULL_PAGE';
export const NETWORK_ERROR_LOGGING_IN_FULL_PAGE = 'NETWORK_ERROR_LOGGING_IN_FULL_PAGE';

/*
export const BEFORE_VERIFY_EXISTING_TOKEN = 'BEFORE_VERIFY_EXISTING_TOKEN';
export const VERIFY_EXISTING_TOKEN = 'VERIFY_EXISTING_TOKEN';
export const NETWORK_ERROR_VERIFYING_EXISTING_TOKEN = 'NETWORK_ERROR_VERIFYING_EXISTING_TOKEN';
*/

export const VALIDATION_ERROR = 'VALIDATION_ERROR';
export const SHOWALERT = 'SHOWALERT';
export const DISMISSALERT = 'DISMISSALERT';