import axios from 'axios';
import {
    BEFORE_LOGIN_FULL_PAGE,
    LOGIN_FULL_PAGE,
    NETWORK_ERROR_LOGGING_IN_FULL_PAGE,
    //BEFORE_VERIFY_EXISTING_TOKEN,
    //VERIFY_EXISTING_TOKEN,
    //NETWORK_ERROR_VERIFYING_EXISTING_TOKEN
} from '../types/login.types';
import {postRequest} from "helpers/httpRequest/request"
import {handleError} from "helpers/error/error"
import { networkNotification } from 'components/notifications/Notifications';

import {endpoint} from "config";


export const beforeLogin = () => (
    { type: BEFORE_LOGIN_FULL_PAGE }
);

export const login = (data) => (
    async (dispatch) => {
        try{
            const response = await axios({
                method: 'post',
                url: `${endpoint}login`,
                headers: {
                    "Accept" : "application/json",
                    "Content-Type" : "application/json",
                },
                data
            });
            dispatch({ type: LOGIN_FULL_PAGE, payload: response });

        }catch(e){
            dispatch({type: NETWORK_ERROR_LOGGING_IN_FULL_PAGE, payload: e.response });
        }
    }
)

/*
export const login = (body) => (
    async (dispatch) => {
        return postRequest('v1', 'login', body)
        .then((data)=>{
            networkNotification(data.message, 200)
            dispatch({ type: LOGIN_FULL_PAGE, payload: data })
        })
        .catch((error)=>{
            handleError(error)
            dispatch({ type: NETWORK_ERROR_LOGGING_IN_FULL_PAGE, payload: error });
        })
            
    }
)
*/

/*
export const beforeVerifyLogin = () => (
    { type: BEFORE_VERIFY_EXISTING_TOKEN }
);

export const verifyLogin = (token, data) => (
    async (dispatch) => {
        try{
            const response = await axios({
                method: 'post',
                url: `${endpoint}auth`,
                headers: {
                    "Accept" : "application/json",
                    "Authorization" : `Bearer ${token}`
                },
                data
            });
            dispatch({ type: VERIFY_EXISTING_TOKEN, payload: response });

        }catch(e){
            dispatch({type: NETWORK_ERROR_VERIFYING_EXISTING_TOKEN, payload: e.response });
        }
    }
)
*/


/*
export const verifyLogin = (body) =>(
    async (dispatch)=>{
        return postRequest('v1', 'auth', body)
        .then((data)=>{
            window.location.href = data.result.user.redirect_url;
        }).catch((error)=>{
            handleError(error);
        });
    }
)
*/