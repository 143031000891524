import {
    BEFORE_LOGIN_FULL_PAGE,
    LOGIN_FULL_PAGE,
    NETWORK_ERROR_LOGGING_IN_FULL_PAGE,
    //BEFORE_VERIFY_EXISTING_TOKEN,
    //VERIFY_EXISTING_TOKEN,
    //NETWORK_ERROR_VERIFYING_EXISTING_TOKEN
} from '../types/login.types';

const initialState = {
    loading: false,
    status_code: null,
    messages: [],
    redirect_url: null,
    token: null,

    alertMessage: [],
	alertStatus: '',

    verification_loading: false,
    verification_auth_user: null,
    verification_status_code: null,
    verification_messages: [],
    verification_redirect_url: null
}

export default function(state = initialState, action){
    switch(action.type){
        case BEFORE_LOGIN_FULL_PAGE: {
            return Object.assign({}, state, {
                loading: true
            });
        }
        case LOGIN_FULL_PAGE: {
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: action.payload.data.message,
                token: action.payload.data.result.token,
                redirect_url: action.payload.data.result.redirect_url
            }); 
        }    
        case NETWORK_ERROR_LOGGING_IN_FULL_PAGE: {
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: action.payload.data.message
            });
        }
        /*
        case BEFORE_VERIFY_EXISTING_TOKEN: {
            return Object.assign({}, state, {
                verification_loading: true,
            });
        }
        case VERIFY_EXISTING_TOKEN: {
            return Object.assign({}, state, {
                verification_loading: false,
                verification_auth_user: action.payload.data.result.user,
                verification_status_code: action.payload.status,
                verification_messages: action.payload.data.message,
                verification_redirect_url: action.payload.data.result.redirect_url
            });
        }
        case NETWORK_ERROR_VERIFYING_EXISTING_TOKEN: {
            return Object.assign({}, state, {
                verification_loading: false,
                verification_status_code: action.payload.status,
                verification_messages: action.payload.data.message
            }); 
        }
        */
        default:
            return state;         
    }
}