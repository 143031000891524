import axios from 'axios';
import {
    BEFORE_CHECK_REQUIRED_AUTHENTICATION,
    CHECK_REQUIRED_AUTHENTICATION,
    NETWORK_ERROR_CHECKING_REQUIRED_AUTHENTICATION
} from '../types/authentication-required.types';

import {endpoint} from "config";

export const beforeCheckAuthentication = () => (
    { type: BEFORE_CHECK_REQUIRED_AUTHENTICATION }
);

export const checkAuthentication = (token, data) => (
    async (dispatch) => {
        try{
            const response = await axios({
                method: 'post',
                url: `${endpoint}auth`,
                headers: {
                    "Accept" : "application/json",
                    "Authorization" : `Bearer ${token}`
                },
                data
            });
            dispatch({ type: CHECK_REQUIRED_AUTHENTICATION, payload: response });
        }catch(e){
            dispatch({type: NETWORK_ERROR_CHECKING_REQUIRED_AUTHENTICATION, payload: e.response });
        }
    }
)