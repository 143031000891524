import {clearToken} from "helpers/authentication/token";
// import {popAlert} from "helpers/alert/popUpAlert";
import {networkNotification} from 'components/notifications/Notifications';

export const handleError = (error, status = 500)=>{
    let errorResponseData;
    if(error.response !== undefined){
        errorResponseData = error.response.data 
        status =  error.response.status

        if(status === 422){
            let newMessage = []; 
            let messages = Object.values(error.response.data.result)
            for(var x = 0; x < messages.length; x++){
                newMessage.push(...messages[x])
            } 
            networkNotification(newMessage, status)
            return;
        }
    

    }else if(error.data !== undefined){
        errorResponseData =  error.data; 
        status = error.status
    }else{
        checkMessage(error.message, status);
        return; 
    }
    checkStatus(status)
    checkMessage(errorResponseData.message, status)       
}

function checkMessage(message, status_code){
    if(message !== undefined){
        if(!Array.isArray(message)){
            message = [message]
        }
        networkNotification(message, status_code);  
    }       
}

function checkStatus(status){
    if(status === 401){
        clearToken();
        window.location.href = "/"
    }
}