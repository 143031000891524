import React, { Component } from "react";
import { BrowserRouter, Route} from "react-router-dom";
import { connect } from "react-redux";

import Login from '../pages/theme-four/login/Login';
import Register from '../pages/theme-four/register/Register';
import ForgotPassword from '../pages/theme-four/forgot-password/ForgotPassword';
import PasswordReset from '../pages/theme-four/password-reset/PasswordReset';
import VerifyEmail from '../pages/theme-four/verify-email/VerifyEmail';
import ResendVerification from '../pages/theme-four/resend-verification/ResendVerification';
import Apps from '../pages/theme-four/apps/Apps';
import VerifyAuthentication from '../pages/theme-four/common/middlewares/verify-authentication/VerifyAuthentication';
import AuthenticationRequired from '../pages/theme-four/common/middlewares/authentication-required/AuthenticationRequired';
import PrivacyPolicy from '../pages/theme-four/privacy-policy/PrivacyPolicy';

class ThemeFourRoute extends Component {

	componentDidMount(){
		
	}

	render() {
		return (
			<BrowserRouter>
				<Route exact={true} path="/" render={(props) => <VerifyAuthentication><Login /></VerifyAuthentication>} />
				<Route exact={true} path="/apps" render={(props) => <AuthenticationRequired><Apps /></AuthenticationRequired>} />
				<Route exact={true} path="/signin" render={(props) => <VerifyAuthentication><Login /></VerifyAuthentication>} />
				<Route exact={true} path="/register" render={(props) => <VerifyAuthentication><Register /></VerifyAuthentication>} />
				<Route exact={true} path="/forgot-password" render={(props) => <VerifyAuthentication><ForgotPassword /></VerifyAuthentication>} />
				<Route exact={true} path="/password-reset" render={(props) => <VerifyAuthentication><PasswordReset/></VerifyAuthentication>} />
				<Route exact={true} path="/verification/email" render={(props) => <AuthenticationRequired><VerifyEmail /></AuthenticationRequired>} />
				<Route exact={true} path="/verification/email/resend" render={(props) => <AuthenticationRequired><ResendVerification /></AuthenticationRequired>} />
				<Route exact={true} path="/privacy-policy" render={(props) => <AuthenticationRequired><PrivacyPolicy /></AuthenticationRequired>} />
			</BrowserRouter>
		);
	}
}

export default connect(null, null)(ThemeFourRoute);
