import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';


import * as Actions from './actions/authentication-required.actions';
import {
	getLocalToken,
	clearLocalToken,
	setLocalToken
} from "helpers/authentication/token";
import {
	getParameter
} from 'helpers/url/url-parameters';


class AuthenticationRequired extends Component {
	
	constructor(props){
		super(props);
		this.state = {
			user: null
		}
	}

	async componentDidMount(){
		const token = getLocalToken();
		if(token !== null){
			const { beforeCheckAuthentication, checkAuthentication } = this.props.actions;
			let redirect_url = getParameter('redirect_url', null);
			let product_code = getParameter('product_code', null);
			await beforeCheckAuthentication();
			await checkAuthentication(token, {product_code, redirect_url});

			const { verification_auth_user, verification_status_code } = this.props.authenticationRequiredReducer;
			if(verification_status_code == 200 && verification_auth_user){
				//authentication is valid, allow children components to render
				this.setState({user: verification_auth_user});
			}else{
				/* Clear Local Token */
				clearLocalToken();
				this.redirect();
			}
		}else{
			this.redirect();
		}
    }

	redirect = () => {
		//on these specific routes we will not need to redirect if user is not logged in
		console.log("Path Name", window.location.pathname);
		if(!['/verification/email/resend', '/verification/email/', '/privacy-policy'].includes(window.location.pathname)){
			window.location = '/';
		}
		
	}

	render() {

		const { user } = this.state;
		const { verification_loading , verification_auth_user } = this.props.authenticationRequiredReducer;

		return (
			<>
				{verification_loading
				?
					<></>
				:
					React.cloneElement(this.props.children, {auth_user: verification_auth_user})
				}
			</>
		);
	}
}

const mapStateToProps = (state) => ({ authenticationRequiredReducer: state.authenticationRequiredReducer });

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthenticationRequired));
