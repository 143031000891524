import React , { Component } from 'react';
import axios from 'axios';
import { 
    getLocalToken, 
    setLocalToken,
    clearLocalToken
} from '../../../helpers/authentication/token';
import {endpoint} from "config";


class Layout extends Component {

    routes = ['/', '/register', '/forgot-password'];

    constructor(props){
        super(props);

        this.state = {
            shouldRedirect:  this.shouldRedirect()
        }
    }
    

    componentDidMount(){
        this.removeOtherStyles();
    }

    removeOtherStyles = () => {

    }

    shouldRedirect = () => {
        return getLocalToken() && this.routes.includes(window.location.pathname)
    }


    links = () => {
        const display = this.props.auth_user ? false : true;
        const displayVerify = this.props.auth_user && this.props.auth_user.email_verified_at != null ? false : true; 
        const links = [
            {title: 'Apps', href: '/apps', display: !display},
            {title: 'Login', href: '/', display},
            {title: 'Register', href: '/register', display},
            {title: 'Forgot Password', href: '/forgot-password', display: false},
            {title: 'Verify E-Mail', href: '/verification/email/resend', display: displayVerify},
        ];

        return links;
    }


    logout = async (e) => {
        e.preventDefault();
        try{
            const response = await axios({
                method: 'post',
                url: `${endpoint}logout`,
                headers: {
                    "Accept" : "application/json",
                    "Content-Type" : "application/json",
                    "Authorization" : `Bearer ${getLocalToken()}`
                },
                data: {}
            });
            this.unsetAndredirect();
        }catch(e){
            this.unsetAndredirect();
        }
    }


    unsetAndredirect = () => {
        clearLocalToken();
        window.location = '/';
    }

    render = () => {

        const pathName = window.location.pathname;

        const homeUrl = this.props.auth_user ? '/apps' : '/';

        return(
            <>
                
                <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
                    <a className="navbar-brand" href={homeUrl}><img src="/logo192.png" alt="" height="25" /></a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        
                        <ul className="navbar-nav mr-auto">
                            {
                                this.links().map(
                                    link => (
                                        link.display &&
                                        <li className={`nav-item ${pathName == link.href ? 'active' : ''}`}>
                                            <a className="nav-link" href={link.href}>{link.title}</a>
                                        </li>  
                                    )
                                )
                            }
                            
                        </ul>

                        { this.props.auth_user && 
                        <ul className="nav navbar-nav navbar-right">
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {this.props.auth_user.firstname} {this.props.auth_user.lastname} &nbsp;
                                </a>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                                    {   
                                        this.links().map(
                                            link => (
                                                link.display &&
                                                <a className="dropdown-item" href={link.href}>{link.title}</a> 
                                            )
                                        )
                                    }
                                    <div className="dropdown-divider"></div>
                                    <a className="dropdown-item" href="#" onClick={this.logout}>Logout</a>
                                </div>
                            </li>
                        </ul> 
                        }   

                    </div>

                </nav>

                <main className="container mt-5">
                    {this.props.children}
                </main>
                
            </>
        );
    }
}

export default Layout;